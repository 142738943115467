import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    stripeSettings: {
      publish_key: null,
    },
    cardknoxSettings: {
      cardknox_api_key: null,
      cardknox_ifields_key: null,
    },
    paymentType: 'stripe',
  },
  getters: {
    getStripeSettings: state => state.stripeSettings,
    getStripePublishKey: state => state.stripeSettings.publish_key,
    getCardknoxSettings: state => state.cardknoxSettings,
    getCardknoxApiKey: state => state.cardknoxSettings.cardknox_api_key,
    getCardknoxIFieldsKey: state => state.cardknoxSettings.cardknox_ifields_key,
    getPaymentType: state => state.paymentType,
    getIsCardknox: state => state.paymentType === 'cardknox',
    getIsStripe: state => state.paymentType === 'stripe',
    getCardknoxIFieldsOptions: state => {
      return {
        xKey: state.cardknoxSettings.cardknox_ifields_key,
        xSoftwareVersion: "1.0.0",
        xSoftwareName: "Bolder",
      }
    },
  },
  mutations: {
    SET_STRIPE_SETTINGS(state, val) {
      state.stripeSettings = val
    },
    SET_CARDKNOX_SETTINGS(state, val) {
      state.cardknoxSettings = val
    },
    SET_PAYMENT_TYPE(state, val) {
      state.paymentType = val
    },
  },
  actions: {
    async fetchPaymentSettings({ commit }) {
      try {
        const response = await axios.get('payment-settings')
        commit('SET_STRIPE_SETTINGS', {
          publish_key: response.data.data.publish_key
        })
        commit('SET_CARDKNOX_SETTINGS', {
          cardknox_api_key: response.data.data.cardknox_api_key,
          cardknox_ifields_key: response.data.data.cardknox_ifields_key,
        })
        commit('SET_PAYMENT_TYPE', response.data.data.provider)
        return response
      } catch (error) {
        return error
      }
    },
  },
}