import ability from './ability'

// export const canNavigate = to => to.matched.some(route => ability.can(route.meta.action || 'read', route.meta.resource))
export const canNavigate = to => to.matched.some(route => {
  if (route.meta.resource) {
    if (Array.isArray(route.meta.resource)) {
      return route.meta.resource.some(item => ability.can(route.meta.action || 'read', item))
    }
    return ability.can(route.meta.action || 'read', route.meta.resource)
  }
  return true
})
export const _ = undefined
